<template>
  <div class="shareMain">
    <!-- <div class="text langEnB">{{ langCommon.information }}</div>
    <div>
      <a href="javascript:;"></a>
      <a href="javascript:;"></a>
      <a href="javascript:;"></a>
      <a href="javascript:;"></a>
    </div>     -->
    <img src="../../assets/img/slogan.png" alt="">
  </div>
</template>
<script>
export default {
  
  computed: {
    ...Vuex.mapGetters([
      "langCommon",
      "screen",
    ]),
  }
}
</script>
<style lang="stylus" scoped>
.shareMain{
  height: 2.88rem;  
  flex(column,nowrap, center,center)
  // background-image: url("../../assets/img/information.png");
  background:  url("../../assets/img/information.png") center center;
  background-size: cover;
  & img{
    width: 4.6rem;
  }
}
a{
  display: inline-block;
  width: .52rem;
  height: .52rem;
  background-size: 100%;
  background-position: center center;
  margin: 0 .25rem;
  &:nth-child(1){
    background-image: url("../../assets/img/fb.png");
  }
  &:nth-child(2){
    background-image: url("../../assets/img/tw.png");
  }
  &:nth-child(3){
    width: .58rem;
    background-image: url("../../assets/img/youtube.png");
  }
  &:nth-child(4){
    background-image: url("../../assets/img/ins.png");
  }
}
.text{
  font-size: $Ftm4;
  color: $Cf;
  text-transform:uppercase;
  margin-bottom: .04rem;
}

@media screen and (max-width: 980px){
  .shareMain{
    & img{
      width: 2.6rem;
    }
  }
  a {
    margin: 0 .22rem;
  }
}
</style>