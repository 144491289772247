<template>
  <div class="contact">
    <div class="imgContent" :style="{backgroundImage:'url('+ bannerFn() +')'}">      
      <img v-if="screen" :src="bannerFn()" alt="">
    </div>
    <!-- <div class="content flex">
      <ul>
        <li>
          <h3>{{ langContactUs.playerSupport }}</h3>
          <span>Support@Amusement.ai</span>
        </li>
        <li>
          <h3>{{ langContactUs.contactBT }}</h3>
          <span>Business@Amusement.ai</span>
        </li>
        <li>
          <h3>{{ langContactUs.inquiries }}</h3>
          <span>Cooperation@Amusement.ai</span>
        </li>
      </ul>
    </div> -->
    <i-main-share></i-main-share>
  </div>
</template>
<script>
import IMainShare from "@/components/share/main.vue"
export default {
  data(){
    return {
      form:{
        message:"",
        subject: "",
        email: "",
        name: "",
      },
      formStatus: false,
      sendStatus: false,
    }
  },
  components: {
    IMainShare
  },
  computed: {
    ...Vuex.mapGetters([
      "contactImg",
      "langContactUs",
      "langCommon",
      "screen",
    ]),
  },
  methods: {
    sendFn(){
      if(!this.form.message || !this.form.subject || !this.form.email || !this.form.name){
        this.formStatus = true;
        return ;
      }
      this.formStatus = false;
      this.$postAll("https://www.amusgame.net/api/web/game_cooperation_intention.add",this.form).then(res=>{
        this.$alert({
          text: this.langCommon.success,
          img: require("@/assets/img/success.png"),
          textStyle:{
            color: "#101010"
          }
        })
        console.log(res);
      }).catch(e=>{this.$alert({
          text: this.langCommon.failed,
          img: require("@/assets/img/error.png"),
          textStyle:{
            color: "#101010"
          }
        })

      })
    },
    bannerFn(){
      if(this.screen){
        let banner = this.contactImg.banner;
        banner = banner.slice(0, banner.length - 4) + '2' + banner.slice(-4);
        return banner;
      }else{
        return this.contactImg.banner;
      }
      
    }
  }

}
</script>
<style lang="stylus" scoped>
.contact {  
  & > img{
    width: 100%;  
  }
  & .imgContent{
    background-size: cover;
    background-position: center center;
    height: 656px;
    overflow: hidden;  
    position: relative;
    & > img{
      width: 100%;  
    }
  }
}
.content{
  font-size: $Fts3;
  max-width: 15.432rem;
  padding: 0 .16rem; 
  margin: 1.4rem auto .9rem;
  & h3{
    font-size: $Fts2;  
    margin-bottom: .24rem;
  }
  & > div{
    width: 100%;
    padding-left: 8%;
    box-sizing: border-box; 
    color: $CFtGy2;
  }
  & input{
    border-bottom: .015rem solid $CFtGy2;
    height: .56rem;
    line-height: .56rem;
    width: 29.3%;
    margin-right: 6%;
    border-radius(0);
    &:last-child{
      margin-right: 0;  
    }
    &.active{
      border-bottom-color: red;  
    }
  }
  & textarea{
    border-radius(0);
    width: 100%;
    border-bottom: .015rem solid $CFtGy2;
    height: 1.4rem;
    margin-top: .4rem;
    resize:none;
    &.active{
      border-bottom-color: red;  
    }
  }
  & a{
    display: inline-block;
    color: $CLGy1;
    border: solid $CLGy1 .01rem;
    padding: .09rem .78rem;
    margin-top: .36rem;    
    font-size: $Fts2;
    &.enter{
      animation: sendEnterKey 0.2s ease-in-out;
      -webkit-animation: sendEnterKey 0.2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
      -webkit-animation-delay: 0.1s;
    }
    &.leave{
      animation: sendLeaveKey 0.2s ease-in-out;
      -webkit-animation: sendLeaveKey 0.2s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}
ul {
  min-width: 3.6rem;
  & li{
    height: 1.2rem;
    & span{
      color: $CLGy1;  
      padding-top: .04rem;
    }
  }
}
@media screen and (max-width: 1000px){
  .contact {    
    & .imgContent{
      height: auto;
      & > img{
        width: 100%;
      } 
    }
    & h3{
      margin-bottom: .14rem;
    }
    & ul li {
      height: 1rem;  
    }
  }
  .content{
    font-size: $Fts2;
    margin: .6rem 0;
    flex(row,wrap);  
    & > div {
      padding-left: 0;  
      text-align: center;
      & a {
        text-align: center;
      }
    } 
    & input{
      display: block;
      margin: .24rem 0;
      width: 100%;
    }  
    & textarea{
      margin-top: 0;  
    }
  }
}
@keyframes sendEnterKey {
  from {
    color: $CLGy1;
    background-color: $Cf;
  }
  to {
    color: $Cf;
    background-color: $CLGy1;
  }
}
@keyframes sendLeaveKey {
  from {
    color: $Cf;
    background-color: $CLGy1;
  }
  to {
    color: $CLGy1;
    background-color: $Cf;
  }
}

</style>